import React from "react";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import MainBanner from "../components/DataAnalyticsMLConsulting/MainBanner";
import OurSolutions from "../components/DataAnalyticsMLConsulting/OurSolutions";
import AboutArea from "../components/DataAnalyticsMLConsulting/AboutArea";
import OurMission from "../components/DataAnalyticsMLConsulting/OurMission";
import Funfacts from "../components/DataAnalyticsMLConsulting/Funfacts";
import UpcomingBootcamps from "../components/DataAnalyticsMLConsulting/UpcomingBootcamps";
import HowItWork from "../components/DataAnalyticsMLConsulting/HowItWork";
import RecentProjects from "../components/DataAnalyticsMLConsulting/RecentProjects";
import TeamMembers from "../components/DataAnalyticsMLConsulting/TeamMembers";
import Testimonials from "../components/DataAnalyticsMLConsulting/Testimonials";
import Partner from "../components/DataAnalyticsMLConsulting/Partner";
import BlogPost from "../components/DataAnalyticsMLConsulting/BlogPost";
import StartProject from "../components/Common/StartProject";

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <OurMission />
      {/* <MainBanner /> */}
      <OurSolutions />
      {/* <AboutArea /> */}  
      <Funfacts />
      {/* <UpcomingBootcamps /> */}
      <HowItWork />
      {/* <RecentProjects /> */}
      {/* <TeamMembers /> */}
      {/* <Testimonials /> */}
      {/* <Partner /> */}
      {/* <BlogPost /> */}
      {/* <StartProject /> */}
      {/* <Footer /> */}
    </Layout>
  );
};

export default Home;
